import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const HeadShotStyle = styled.div`
  line-height: 0;
  margin: 1rem 0;
  padding: 0;
  > div {
    border-radius: 50%;
  }
  @media screen and (min-width: ${props => props.theme.mobileMinWidth}) {
    margin: 3rem 0;
  }
`

const HeadShot = () => {
  const headshot = useStaticQuery(
    graphql`
      query HeadshotQuery {
        file (
          name: {
            eq: "ilana-headshot"
          }
        ) {
          childImageSharp {
            fixed (
              width: 150,
              height: 150
            ) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <HeadShotStyle className="headshot-wrapper">
      <Img fixed={headshot.file.childImageSharp.fixed}
      alt="Rabbi Ilana Garber" />
    </HeadShotStyle>
  )
}

export default HeadShot