/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

import SideBar from './sidebar'
import Footer from './footer'

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  ::selection {
    background-color: rgba(111, 63, 152, 0.3);
  }
  body {
    background-color: rgba(0,0,0,0.02);
    color: rgba(0,0,0,0.8);
    font-family: 'Noto Sans';
    margin: 0;
  }
  h1 {
    font-family: 'Noto Serif';
    font-size: 3rem;
    line-height: 1.3;
    width: 100%;
  }
  h2 {
    font-family: 'Noto Serif';
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  p {
    line-height: 1.6;
  }
  a {
    background-image: linear-gradient(#6f3f98 50%, #ffffff 50%);
    background-position: 0px 1.2rem;
    background-repeat: repeat-x;
    background-size: 2px 3px;
    color: rgba(0,0,0,0.8);
    text-decoration: none;
  }
  .screen-reader {
    position: absolute;
    left: -9999px;
  }
  .screen-reader:focus {
    background-color: white;
    left: 1rem;
    top: 1rem;
    padding: 1rem;
  }
`

const theme = {
  darkPurple: '#6f3f98',
  lightGrey: '#fafafa',
  sansSerif: 'Noto Sans',
  serif: 'Noto Serif',
  mobileMinWidth: '500px',
  transformScale: 'transform 0.3s ease-in-out'
}

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media screen and (min-width: 500px) {
    flex-direction: row;
  }
`

const MainContainer = styled.main`
  flex-basis: 0;
  flex-grow: 999;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = data.site.siteMetadata.title

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <FlexWrapper>
          <a className="screen-reader" href="#main">Skip to Main Content</a>
          <SideBar title={title}/>
          <MainContainer aria-live="polite" id="main">
            {children}
          </MainContainer>
          <Footer />
        </FlexWrapper>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
