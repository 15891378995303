import React from 'react';
import styled from 'styled-components'

const FooterWrapper = styled.footer`
  @media screen and (min-width: ${props => props.theme.mobileMinWidth}) {
    flex-basis: 100vw;
  }
  div {
    padding: 1rem;
  }
  p {
    font-size: 0.8rem;
  }
  a {
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  button {
    background-color: inherit;
    border: 1px solid ${props => props.theme.darkPurple};
    display: block;
    padding: 0.5rem;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    &:hover,
    &:focus {
      background-color: ${props => props.theme.darkPurple};
      color: ${props => props.theme.lightGrey};
    }
  }
`

const year = new Date().getFullYear();

const Footer = () => (
  <FooterWrapper>
    <div>
      <p className="copyright">&copy; {year} Ilana Garber</p>
      <p>Site developed by <a href="https://www.adamjberkowitz.com">Adam Berkowitz</a> with <a href="https://gatsbyjs.org">Gatsby</a> and <a href="https://contentful.com">Contentful</a>.</p>
      <button onClick={() => window.cookiehub.openSettings()}>Change cookie settings</button>
    </div>
  </FooterWrapper>
)

export default Footer