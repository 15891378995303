import styled from 'styled-components'

const OffsetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 65ch;
  line-height: 1.5;
  * {
    margin-bottom: 0;
  }
  * + * {
    margin-top: 0.8em;
  }
  ul,
  ol {
    padding-left: 1rem;
    @media screen and (min-width: ${props => props.theme.mobileMinWidth}) {
      padding: 0;
    }
  }
  a:hover,
  a:focus {
    background-color: ${props => `${props.theme.darkPurple}4d`};
  }
`

export default OffsetWrapper