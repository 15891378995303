import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Link from 'gatsby-link'
import styled from 'styled-components'

import Headshot from './headshot'

const Aside = styled.aside`
  flex-basis: 20vw;
  flex-grow: 1;
  div {
    padding: 1rem;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0 1rem;
    @media screen and (min-width: ${props => props.theme.mobileMinWidth}) {
      flex-direction: column;
      margin: 1rem 0;
    }
  }
  p {
    font-family: ${props => props.theme.serif};
    font-size: 2rem;
  }
  a {
    background-image: unset;
    color: inherit;
    text-decoration: none;
  }
  .main-nav__link {
    align-items: center;
    display: flex;
    padding: 1rem 0;
    position: relative;
    transform: scale(1);
    transition: ${props => props.theme.transformScale};
    will-change: transform;
    &:before {
      content: '';
      background-color: ${props => `${props.theme.darkPurple}`};
      border: 1px solid ${props => props.theme.darkPurple};
      border-radius: 50%;
      margin-right: 0.5rem;
      opacity: 0.2;
      transition: opacity 0.3s ease-in-out;
      height: 10px;
      width: 10px;
    }
    &:hover,
    &:focus {
      transform: scale(1.05);
      &:before {
        content: '';
        background-color: ${props => props.theme.darkPurple};
        border: 1px solid ${props => props.theme.darkPurple};
        border-radius: 50%;
        margin-right: 0.5rem;
        opacity: 1;
        height: 10px;
        width: 10px;
      }
    }
  }
`

const SideBar = ({ title }) => (
  <StaticQuery
    query={graphql`
      query mainNavQuery {
        allContentfulPageContent(
          # only display pages.
          # filter out the home page
          # sort alphabetically
          filter: { 
            contentType: { eq: "Page" },
            slug: { ne: "home" } 
          },
          sort: {
            fields: title, 
            order: ASC
          }
        ) {
          edges {
            node {
              title
              slug
              contentful_id
            }
          }
        }
      }
    `}
    render={({ allContentfulPageContent }) => {

      const navItems = allContentfulPageContent.edges.map(({ node }) => {
        return (
          <li key={node.contentful_id}>
            <Link 
              className="main-nav__link" 
              to={`/${node.slug}`}>
                {node.title}
            </Link>  
          </li>
        )
      })

      return (
        <Aside>
          <div>
            <p>
              <Link to="/">{title}</Link>
            </p>
            <Headshot />
            <nav>
              <ul>
                {navItems}
              </ul>
            </nav>
          </div>
        </Aside>
      )
    }}
  />
)

export default SideBar